import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { promo1, promo2, promo3, mobilePromotion, promotions } from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="35px">
          <CFImage src={mobilePromotion} w="85%" alt="Mobile Promotions" />
        </CFView>
        <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={promo1}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
          <CFImage
            src={promo2}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
          <CFImage
            src={promo3}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
        </Slider>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1400px">
          <CFImage
            src={promotions}
            w="73%"
            alt="Promotions"
            mb="100px"
            mt="40px"
          />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
