import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileAbout} w="100%" alt="Manna Sushi About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFImage src={about} w="100%" alt="Manna Sushi About" />
      </DefaultScreen>
    </CFView>
  )
}
