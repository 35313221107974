import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  foodGallery,
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
  slide8,
  slide9,
  mobileGallery,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="35px">
          <CFImage src={mobileGallery} w="85%" alt="Mobile Gallery" />
        </CFView>
        <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={slide1}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
          <CFImage
            src={slide2}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
          <CFImage
            src={slide3}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
          <CFImage
            src={slide4}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
          <CFImage
            src={slide5}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
          <CFImage
            src={slide6}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
          <CFImage
            src={slide7}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
          <CFImage
            src={slide8}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
          <CFImage
            src={slide9}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="20px"
          />
        </Slider>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="80px auto">
          <CFImage src={foodGallery} w="73%" alt="Food Gallery" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
